<template>
  <el-card>
    <common-user-center-title :title="'我的红包'"></common-user-center-title>
    <div class="title-option">
      <el-tabs v-model="activeName" @tab-click="topSwitchClick">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane label="未使用" name="0"></el-tab-pane>
        <el-tab-pane label="已使用" name="1"></el-tab-pane>
        <el-tab-pane label="已过期" name="2"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="red-envelope-list">
      <div class="red-envelope-item" v-for="item in redEnvelopeList" :key="item.id">
        <div>
          <div v-if="item.time.hou">
            剩余时间：{{item.time.hou}}:{{item.time.min}}:{{item.time.sec}}
          </div>
          <div v-else>已过期</div>
        </div>
        <div>满{{item.full_money}}减{{item.red_envelopes_money}}</div>
      </div>
    </div>
  </el-card>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { countDownUtil } from '@/utils/util.js'
import {getMyRedEnvelopeListUrl} from '@/api/index'
export default {
  name: 'MyRedEnvelopeList',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      queryInfo:{
        status:'',
        pageSize: 10,
        page: 1,
        field: '',
        desc: '',
      },
      activeName:'-1',
      redEnvelopeList:[],
      redEnvelopeTotal:[],
    }
  },
  created () {
    this.initData()
  },
  methods:{
    initData(){
      this.getRedEnvelopeList()
    },
    // 获取我的红包列表
    async getRedEnvelopeList(){
      const{data:res} = await this.$http.get(getMyRedEnvelopeListUrl,{params:this.queryInfo})
      console.log(res)
      if(res.code !== 200) return this.$message.error(res.msg)
      this.redEnvelopeList = res.data.list
      this.redEnvelopeList.forEach(item=>{
        let time = countDownUtil(item.used_end_time)
        this.$set(item,'time',time)
      })
      this.redEnvelopeTotal = res.data.total
      console.log(this.redEnvelopeList)
    },
    // 顶部分类切换事件
    topSwitchClick(){
      switch (this.activeName) {
        case '-1':
          this.queryInfo.status = ''
          this.queryInfo.page = 1
          this.getRedEnvelopeList()
          break
        case '0':
          this.queryInfo.status = '0'
          this.queryInfo.page = 1
          this.getRedEnvelopeList()
          break
        case '1':
          this.queryInfo.status = '1'
          this.queryInfo.page = 1
          this.getRedEnvelopeList()
          break
        case '2':
          this.queryInfo.status = '2'
          this.queryInfo.page = 1
          this.getRedEnvelopeList()
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .title-option {
    position: absolute;
    top: 14px;
    left: 120px;

    .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
  }

</style>
