// 防抖函数
export function debounce (fn, delay = 500) {
  var timer
  return function() {
    var args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)   // this 指向vue
    }, delay)
  }
}

// 倒计时
export function countDownUtil (endData, startData = Number(String(new Date().getTime()).substring(0, 10))) {
  if (startData) {
    if (Date.parse(startData)) {
      // 如果有开始时间 如果是YY-dd-MM格式的，转化为时间戳
      startData = Date.parse(startData) / 1000
    }
  }
  if (Date.parse(endData)) {
    // 如果是YY-dd-MM格式的，转化为时间戳
    endData = Date.parse(endData) / 1000
  }
  let timeObj = {
    hou: '',
    min: '',
    sec: ''
  }
  let surplusTime = endData - startData
  if (surplusTime > 0) {
    timeObj.hou =
      parseInt((surplusTime % (60 * 60 * 24)) / 3600) < 10
        ? '0' + parseInt((surplusTime % (60 * 60 * 24)) / 3600)
        : parseInt((surplusTime % (60 * 60 * 24)) / 3600)
    timeObj.min =
      parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60) < 10
        ? '0' + parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60)
        : parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60)
    timeObj.sec =
      parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60) < 10
        ? '0' + parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60)
        : parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60)

    let timer = setInterval(() => {
      --timeObj.sec
      if (timeObj.sec < 0) {
        timeObj.sec = 59
        --timeObj.min
        if (timeObj.min < 0) {
          timeObj.min = 59
          --timeObj.hou
          if (timeObj.hou < 0) {
            // 如果秒杀结束，清除倒计时，重新获取秒杀接口
            clearInterval(timer)
          } else if (timeObj.hou < 10) {
            // 小于10在前面追加0
            timeObj.hou = '0' + timeObj.hou
          }
        } else if (timeObj.min < 10) {
          // 小于10在前面追加0
          timeObj.min = '0' + timeObj.min
        }
      } else if (timeObj.sec < 10) {
        // 小于10在前面追加0
        timeObj.sec = '0' + timeObj.sec
      }
    }, 1000)
  }
  return timeObj
}


// 保存图片到本地（id一定要加上#）
export function downloadImg (imgId, name) {
  // 通过选择器获取img元素
  var img = document.querySelector(imgId)
  // 将图片的src属性作为URL地址
  var url = img.src
  var a = document.createElement('a')
  var event = new MouseEvent('click')
  a.target = '_blank'
  a.download = name || '我的图片'
  a.href = url
  a.dispatchEvent(event)
}

